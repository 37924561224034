// @flow
import React from 'react'
import classNames from 'classnames'

import PageRow from 'src/common/components/PageRow'
import PostContent from 'src/common/components/PostContent'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'

import styles from './LandingPageHeader.module.scss'

type Props = {|
  heading: string,
  description: string,
  list: Array<ButtonProps>,
|}

export default function LandingPageHeader({
  heading,
  description,
  list,
  className,
}: Props) {
  return (
    <PageRow firstRow className={classNames(styles.pageRow, className)}>
      <div className={styles.container}>
        <h1 className={styles.header}>{heading}</h1>
        <div className={styles.content}>
          <PostContent content={description} />
        </div>
        <div className={styles.buttonContainer}>
          {list &&
            list.map((button, i) => {
              if (i === 0)
                return (
                  <ButtonLink key={button.text} color="white" {...button} />
                )
              else
                return (
                  <ButtonLink
                    key={button.text}
                    color="transparent"
                    {...button}
                  />
                )
            })}
        </div>
      </div>
    </PageRow>
  )
}
