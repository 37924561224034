// @flow
import React from 'react'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'
import { SectionGrid } from 'src/common/components/Section'
import PostContent from 'src/common/components/PostContent'
import Image from 'src/common/components/Image'

import styles from './SenecaImpact.module.scss'

type ContentProps = {|
  heading: string,
  description: string,
  button: ButtonProps,
  imageLink: string,
  imageAlt: string,
|}

type Props = {|
  content: ContentProps,
|}

const SenecaImpact = ({
  content: { heading, description, button, imageLink, imageAlt },
}: Props) => {
  return (
    <SectionGrid>
      <div className={styles.content}>
        <div className={styles.header}>{heading}</div>
        <PostContent className={styles.text} content={description} />
        <ButtonLink color="blue" {...button} />
      </div>
      <div className={styles.image}>
        <Image
          src={imageLink}
          alt={imageAlt}
          widths={{ mobile: 320, tablet: 430, desktop: 430 }}
          initialAspectRatio={1.12}
        />
      </div>
    </SectionGrid>
  )
}

export default SenecaImpact
