//@flow

import * as React from 'react'
import CountUp from 'react-countup'
import classNames from 'classnames'

import styles from './StatTicker.module.scss'

type Props = {
  initialTimeStamp: number,
  initialValue: number,
  dailyGrowthRate: number,
  className: string,
}

type State = {
  previousValue: number,
  currentValue: number,
}

class StatTicker extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { previousValue: 0, currentValue: this.props.initialValue }
    this.intervalID = null
  }

  componentDidMount() {
    this.getCurrentValue(true)
    this.intervalID = setInterval(() => this.getCurrentValue(), 1000)
  }

  componentDidUpdate(prevProps, prevState) {
    // if prevState not the same as before update the firstone
    if (prevState.currentValue !== this.state.currentValue) {
      this.setState({ previousValue: prevState.currentValue })
    }
  }

  getCurrentValue(didJustMount = false) {
    const { initialTimeStamp, initialValue, dailyGrowthRate } = this.props

    const nowTimeStamp = Date.now()
    const milliSecondsInADay = 86400000
    const secondsSinceStart = nowTimeStamp - initialTimeStamp
    const wholeDaysSinceStart = Math.round(
      secondsSinceStart / milliSecondsInADay
    )
    const valueAtStartOfToday =
      initialValue + wholeDaysSinceStart * dailyGrowthRate
    const secondsElapsedToday =
      secondsSinceStart - wholeDaysSinceStart * dailyGrowthRate
    const roundedValueGrowthToday =
      (secondsElapsedToday / milliSecondsInADay) * dailyGrowthRate

    let newCurrentValue = Math.round(
      valueAtStartOfToday + roundedValueGrowthToday
    )
    const { currentValue: prevCurrentValue } = this.state

    // if (didJustMount) {
    //   // if you just mounted reduce the estimation to gurantee uptick
    //   newCurrentValue = this.state.currentValue - Math.floor(Math.random() * 7) + 1
    // }

    // only update 50% on the time to create non-linearity in updates
    if (Math.random() > 0.5 || didJustMount) {
      // always set value when just mounted
      this.setState({
        currentValue: newCurrentValue,
        previousValue: prevCurrentValue,
      })
    }
  }

  componentWillUnmount() {
    // require to not set set state on unmounted component
    clearInterval(this.intervalID)
  }

  render() {
    const { previousValue, currentValue } = this.state
    return (
      <CountUp
        start={previousValue}
        end={currentValue}
        duration={1}
        className={classNames(styles.display, this.props.className)}
        formattingFn={value => {
          if (!value) return ''
          const valueString = value.toString()
          const separatorOffset = valueString.length % 3
          return valueString
            .split('')
            .map((char, idx) => {
              return `<span class="${classNames(styles.char, {
                [styles.separator]: (idx + 1 - separatorOffset) % 3 === 0,
              })}">${char}</span>`
            })
            .join('')
        }}
      />
    )
  }
}

export default StatTicker
