//@flow
import React from 'react'
import { isMobile } from 'react-device-detect'

import VideoPreview from 'src/common/components/VideoPreview'
import PageRow from 'src/common/components/PageRow'
import Image from 'src/common/components/Image'

import styles from './AppPreviewSection.module.scss'

type Props = {|
  content: {|
    assetLink: string,
    assetLinkMobile: string,
    assetAlt: string,
  |},
|}

const AppPreviewSection = ({
  content: { assetLink, assetLinkMobile, assetAlt },
}: Props) => {
  const asset = isMobile && assetLinkMobile ? assetLinkMobile : assetLink
  return (
    <PageRow>
      <div className={styles.container}>
        {asset.endsWith('.mp4') ? (
          <VideoPreview videoLink={asset} />
        ) : (
          <Image
            src={asset}
            alt={assetAlt}
            widths={{ mobile: 560, tablet: 800, desktop: 960 }}
          />
        )}
      </div>
    </PageRow>
  )
}

export default AppPreviewSection
