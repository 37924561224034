// @flow
import * as React from 'react'

import styles from './Description.module.scss'

type Props = {|
  text: React.Node,
|}

export default function Description({ text }: Props) {
  if (text)
    return (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  return null
}
