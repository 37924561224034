// @flow
import * as React from 'react'

import styles from './SectionWrapper.module.scss'

type Props = {|
  children: React.Node,
|}

export default function SectionWrapper({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>
}
