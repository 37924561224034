// @flow
import * as React from 'react'

import SectionWrapper from './SectionWrapper'
import styles from './SectionColumn.module.scss'

type Props = {|
  children: React.Node,
|}

export default function SectionColumn({ children }: Props) {
  return (
    <SectionWrapper>
      <div className={styles.wrapper}>{children}</div>
    </SectionWrapper>
  )
}
