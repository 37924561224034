import React from 'react'

import PageRow from 'src/common/components/PageRow'
import StatTicker from 'src/components/StatTicker'

import styles from './QuestionsAnswered.module.scss'

type Content = {|
  heading: string,
  initialTimeStamp: number,
  initialValue: number,
  dailyGrowthRate: number,
|}

type Props = {|
  content: Content,
|}

export default function QuestionsAnswered({
  content: { initialTimeStamp, initialValue, dailyGrowthRate, heading },
}: Props) {
  return (
    <PageRow className={styles.row}>
      <div className={styles.container}>
        <StatTicker
          initialTimeStamp={initialTimeStamp}
          initialValue={initialValue}
          dailyGrowthRate={dailyGrowthRate}
          className={styles.count}
        />
        <div className={styles.content}>{heading}</div>
      </div>
    </PageRow>
  )
}
