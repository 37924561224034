// @flow
import * as React from 'react'

import SectionWrapper from './SectionWrapper'
import styles from './SectionGrid.module.scss'

type Props = {|
  children: React.Node,
  gridTemplateColumns?: string,
|}

export default function SectionGrid({ children, gridTemplateColumns }: Props) {
  return (
    <SectionWrapper>
      <div className={styles.wrapper} style={{ gridTemplateColumns }}>
        {children}
      </div>
    </SectionWrapper>
  )
}
