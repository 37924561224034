// @flow
import React from 'react'

import { SectionColumn } from 'src/common/components/Section'
import Description from 'src/common/components/Description'
import Heading from 'src/common/components/Heading'
import Image from 'src/common/components/Image'

import styles from './Partners.module.scss'

type Partner = {|
  imageLink: string,
  imageAlt: string,
|}

type Props = {|
  content: {|
    showSection: boolean,
    heading: string,
    description: string,
    list: Array<Partner>,
  |},
|}

export default function Partners({
  content: { heading, description, list },
}: Props) {
  return (
    <SectionColumn>
      <Heading text={heading} level="2" color="black" />
      <Description text={description} />
      <div className={styles.partners}>
        {list.map(item => (
          <div className={styles.image} key={item.imageLink}>
            <Image
              src={item.imageLink}
              alt={item.imageAlt}
              widths={{ desktop: 150 }}
              initialAspectRatio={1}
            />
          </div>
        ))}
      </div>
    </SectionColumn>
  )
}
