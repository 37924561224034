// @flow
import React from 'react'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'
import PostContent from 'src/common/components/PostContent'
import { SectionGrid } from 'src/common/components/Section'
import Image from 'src/common/components/Image'

import styles from './CourseDiscovery.module.scss'

type ContentProps = {|
  heading: string,
  description: string,
  button: ButtonProps,
  imageLink: string,
  imageAlt: string,
|}

type Props = {|
  content: ContentProps,
|}

const CourseDiscovery = ({
  content: { heading, description, button, imageLink, imageAlt },
}: Props) => {
  return (
    <SectionGrid gridTemplateColumns="2fr 1fr">
      <div className={styles.image}>
        <Image
          src={imageLink}
          alt={imageAlt}
          widths={{ mobile: 600, tablet: 600, desktop: 720 }}
        />
      </div>
      <div className={styles.content}>
        <PostContent className={styles.header} content={heading} />
        <PostContent className={styles.text} content={description} />
        <ButtonLink color="blue" {...button} />
      </div>
    </SectionGrid>
  )
}

export default CourseDiscovery
